import React from 'react';
import './PromoQMICard.scss';

const priceFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
});

export default function PromoQMICard({ qmi, promoSnipe, promoSnipeColor, promoAccentColor }) {
    return (
        <div>
            <div className="promo-qmi-card__snipe" style={{ backgroundColor: promoSnipeColor }}>
                {promoSnipe}
            </div>
            <div className="promo-qmi-card__image">
                <a href={qmi.post_permalink}>
                    <img src={qmi.post_thumbnail} alt={qmi.post_title} />
                </a>
                <span className="promo-qmi-card__status">{qmi.qmi_status}</span>
            </div>
            <div>
                <h3 className="promo-qmi-card__title">{qmi.post_title}</h3>
                <p className="promo-qmi-card__location">
                    {qmi.nbd_name} | {qmi.city}, {qmi.state}
                </p>
                <p className="promo-qmi-card__price">Priced at {priceFormatter.format(qmi.price)}</p>
                <div className="promo-qmi-card__stats">
                    <div className="promo-qmi-card__stat">
                        <span>{qmi.square_feet}</span>
                        <span>Sq. Footage</span>
                    </div>
                    <div className="promo-qmi-card__stat">
                        <span>{qmi.bed}</span>
                        <span>Bedroom</span>
                    </div>
                    <div className="promo-qmi-card__stat">
                        <span>{qmi.bath}</span>
                        <span>Bathrooms</span>
                    </div>
                    <div className="promo-qmi-card__stat">
                        <span>{qmi.garage}</span>
                        <span>Bay Garage</span>
                    </div>
                </div>
                <div>
                    <a
                        style={{ '--accent-color': promoAccentColor }}
                        className="promo-qmi-card__button"
                        href={qmi.post_permalink}
                    >
                        Learn More
                    </a>
                </div>
            </div>
        </div>
    );
}
