import React, {useEffect, useState,useReducer } from "react";
import "./CardSection.scss";
import QmiLandingCards from "./QmiLandingCards";

export default function CardSection(props) {

  var city_data = props.city_detail;

  var city = city_data.filter(function(item) {
        return item.post_status !== "Sold" && item.post_status !== "Reserved"
    });
  var city_name = props.city_name;
  var qmi_length = Object.keys(city).length;
  var state_data = props.state;
  var state_name = state_data.toUpperCase();
  var highlight_class = 'green-color';
  function dateComparison(a, b) {
    const date1 = new Date(a.move_date)
    const date2 = new Date(b.move_date)
    
    return date1 - date2;
}

  const move_data=city.sort(dateComparison);
  const [ test , setTest] = useState('move_data');

  
  return (
    qmi_length > 0 ? <>
    <div className="cardsectiontitle-main-div">
        <div className="sectiontitle-headline">
            <span tabindex="0" className={highlight_class}>{city_name},</span>
            <span> </span>
            <span>{state_name}</span>
        </div>

        <div tabindex="0" className="eyebrow-text-div">
          <div tabindex="0" className="sectiontitle-eyebrow_text eyebrow">{qmi_length} Available Homes
          </div>
          <div className="eyebrowtext-border"></div>
        </div>
    </div>
   {props.test=='move_data' && <QmiLandingCards qmi_data={move_data} />}
   {props.test=='price' && <QmiLandingCards qmi_data={city.sort(function(a, b) {
      return a.price - b.price;
    })} />}
</> : 
   ''
  );
}
