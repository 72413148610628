import React from 'react'
import { srcset } from './Helpers'
import './BuildLocations.scss'
import {Link} from "react-router-dom";

class BuildLocations extends React.Component {
    render() {
        return (
            this.props.image.url && (this.props.headline || this.props.description) ? (
                <section className={`build_locations`}
                         style={{
                             'background-color': this.props.background_color ? this.props.background_color : '#fff',
                         }}>
                    <figure className="image aspect-ratio width"
                            style={{
                                '--aspect-ratio-height': '833',
                                '--aspect-ratio-width': '1101',
                            }}>
                        {this.props.image.url && (
                            <img className="lazy object-fit"
                                 data-srcset={srcset(this.props.image)}
                                 alt={this.props.image.alt ? this.props.image.alt : this.props.image.title}
                            />
                        )}
                    </figure>

                    <div className="content">
                        {this.props.eyebrow_text && (
                            <div className="eyebrow"
                                 style={{
                                     'color': this.props.eyebrow_text_color ? this.props.eyebrow_text_color : 'var(--gray-mid)',
                                 }}
                            >
                                {this.props.eyebrow_text}
                            </div>
                        )}

                        {this.props.headline && (
                            <h2 className={`media-lockup__title title section-header__title section-header__title--small`}>
                                <span style={{'color': this.props.headline_color ? this.props.headline_color : 'var(--gray-mid)' }} dangerouslySetInnerHTML={{ __html: this.props.headline.replace(/(?:\r\n|\r|\n)/g, '') }}></span>
                            </h2>
                        )}

                        {this.props.states.length > 0 && (
                            <div className="state-list max-height"
                                style={{
                                    '--max-height-desktop': '360px',
                                    '--max-height-mobile': '700px',
                                }}
                            >
                                {this.props.states.map(state => (
                                    <div className="width" style={{
                                        '--width-desktop': '175px',
                                        '--width-mobile': 'min(50%, 175px)',
                                    }}>
                                        <div className="font--bold font-size"
                                            style={{
                                                '--font-size': '16px',
                                                'line-height': '24px',
                                                'padding-bottom': '8px',
                                            }}
                                        >{state.name}</div>
                                        {state.submarkets.length > 0 && (
                                            <ul className="list-unstyled">
                                                {state.submarkets.map(submarket => (
                                                    <li
                                                        style={{
                                                            'padding-bottom': '8px',
                                                        }}
                                                    >
                                                        <Link
                                                            to={submarket.url}
                                                            className="font-size"
                                                            style={{
                                                                '--font-size': '14px',
                                                                'line-height': '22px',
                                                            }}
                                                            href="#"
                                                        >{submarket.name}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </section>
            ):('')
        )
    }
}

export default BuildLocations