import React from 'react';
import './CommonNeighCard.scss';
import {Link} from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Carousel from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from 'jquery';



class CommonNeighCard extends React.Component {  

    constructor() {
        super();

        this.state = {
            visible: 6
        };
        this.loadMore = this.loadMore.bind(this);
    }
    componentDidMount() {
        var window_width = window.screen.width;
        var posttype = this.props.posttype;
        if(posttype === 'community'){
            if(window_width > 767){
                var card_content_height = [];
                var plan_card_div = $(document).find(".common-card .card-main-div");
                plan_card_div.each(function () {
                    card_content_height.push($(this).find(".comm_size").outerHeight());
                });
                var box_size = card_content_height.filter(
                    element => typeof element === 'number'
                );
                var card_content_maxheight = Math.max.apply(Math, box_size);
                var cards = document.querySelectorAll('.comm_size');
                cards.forEach(items =>{
                    items.style.height  = card_content_maxheight +'px';
                })
            }
        }if (posttype === 'neighborhood') {
            if(window_width > 767){
                var card_content_height = [];
                var plan_card_div = $(document).find(".common-card .card-main-div");
                plan_card_div.each(function () {
                    card_content_height.push($(this).find(".neigh_size").outerHeight());
                });
                var box_size = card_content_height.filter(
                    element => typeof element === 'number'
                );
                var card_content_maxheight = Math.max.apply(Math, box_size);
                var cards = document.querySelectorAll('.neigh_size');
                cards.forEach(items =>{
                    items.style.height  = card_content_maxheight +'px';
                })
            }
        }if (posttype === 'qmi') {
            if(window_width > 767){
                var card_content_height = [];
    
                var plan_card_div = $(document).find(".common-card .card-main-div");
                plan_card_div.each(function () {
                    card_content_height.push($(this).find(".qmi_size").outerHeight());
                });
                var box_size = card_content_height.filter(
                    element => typeof element === 'number'
                );
                var card_content_maxheight = Math.max.apply(Math, box_size);
                var cards = document.querySelectorAll('.qmi_size');
                cards.forEach(items =>{
                    items.style.height  = card_content_maxheight +'px';
                })
            }
        }
        
    }
    componentDidUpdate(){
        var window_width = window.screen.width;
        var posttype = this.props.posttype;
        if(posttype === 'community'){
            if(window_width > 767){
                var card_content_height = [];
    
                var plan_card_div = $(document).find(".common-card .card-main-div");
                plan_card_div.each(function () {
                    card_content_height.push($(this).find(".comm_size").outerHeight());
                });
                var box_size = card_content_height.filter(
                    element => typeof element === 'number'
                );
                var card_content_maxheight = Math.max.apply(Math, box_size);
                var cards = document.querySelectorAll('.comm_size');
                cards.forEach(items =>{
                    items.style.height  = card_content_maxheight +'px';
                })
            }
        }else if (posttype === 'neighborhood') {
            if(window_width > 767){
                var card_content_height = [];
                var plan_card_div = $(document).find(".common-card .card-main-div");
                plan_card_div.each(function () {
                    card_content_height.push($(this).find(".neigh_size").outerHeight());
                });
                var box_size = card_content_height.filter(
                    element => typeof element === 'number'
                );
                var card_content_maxheight = Math.max.apply(Math, box_size);
                var cards = document.querySelectorAll('.neigh_size');
                cards.forEach(items =>{
                    items.style.height  = card_content_maxheight +'px';
                })
            }
        }else if (posttype === 'qmi') {
            if(window_width > 767){
                var card_content_height = [];
                var plan_card_div = $(document).find(".common-card .card-main-div");
                plan_card_div.each(function () {
                    card_content_height.push($(this).find(".qmi_size").outerHeight());
                });
                var box_size = card_content_height.filter(
                    element => typeof element === 'number'
                );
                var card_content_maxheight = Math.max.apply(Math, box_size);
                var cards = document.querySelectorAll('.qmi_size');
                cards.forEach(items =>{
                    items.style.height  = card_content_maxheight +'px';
                })
            }
        }
    }
    loadMore() {
      
        this.setState({ visible: this.props.card_detail.length });
    }
  render() {    
    // var card_detail = this.props.card_detail; 
    var posttype = this.props.posttype; 
    var page_type = this.props.page_type; 
    var card_size = '';
    if (posttype === 'community') {
         card_size = 'comm_size';
    }
    else if (posttype === 'neighborhood') {
        card_size = 'neigh_size';
    }else if (posttype === 'qmi') {
        card_size = 'qmi_size';
    }
    else{
        card_size = '';
    }
    var window_width = window.screen.width;
    var show_detail = this.props.show_detail;
    if(show_detail === "true"){
    var state_details = this.props.state_abb;
    var state_cap_abb = state_details.state_abb;
    var state_data = state_cap_abb.toUpperCase();
    }
    
    var comm_card = this.props.card_detail;
    if(this.props.sort_order=='move_early'){
    function dateComparison(a, b) {
        const date1 = new Date(a.move_date)
        const date2 = new Date(b.move_date)
        
        return date1 - date2;
    }
    
    comm_card=comm_card.sort(dateComparison);
}
    for(var x in comm_card){
        if(comm_card[x].post_status == "Sold Out" || comm_card[x].post_status == "Sold"  )
        {comm_card.push( comm_card.splice(x,1)[0])}
    }
    for(var x in comm_card){
        if(comm_card[x].post_status == "Sold Out" || comm_card[x].post_status == "Sold")
        {comm_card.push( comm_card.splice(x,1)[0])}
    }
    for(var x in comm_card){
        if(comm_card[x].post_status == "Sold Out" || comm_card[x].post_status == "Sold")
        {comm_card.push( comm_card.splice(x,1)[0])}
    }
    


    
    var load_value = this.props.loadcard;
    if(load_value == 'false'){
        var layout = '';
    }
    else{
        layout = 'col-lg-4 col-sm-6';
    }
    

    const card_options = {
        loop: false,
        nav: true,
        navText: [
            '<div class="sliderArrow arrowLeft"></div>',
            '<div class="sliderArrow arrowRight"></div>'
        ],
        dots: true,
        margin: 0,
        autoplay: false,
        startPosition: 0,
        stagePadding:0,
        responsive: {
            0: {
                items: 1,
                margin: 20,
            },
            667 : {
                items: 2,
            },
            768: {
                items: 2,
            },
            1601: {
                items: 2,
                margin : 46,
            }
            
        }
    }
        return ( 
            comm_card.length > 0 ? [
                //for promotion
                page_type === 'promotion' ?
                    comm_card.length <=2 && window_width > 480 ? (
                    <div className="common-card promo">
                    {comm_card.length > 0 ? [
                        comm_card.map(items => (
                            items.post_status!== "Archive" ? (
                        <div className="card-main-div col-lg-6 col-sm-6">
                            <div className="card-imgs">
                                <div className="plan-img">
                                    {items.card_image.length > 0 ? (
                                        items.card_image.length === 1 ? (
                                            <Link to={items.post_permalink} >
                                                <div className="overlay">
                                                    <img src={items.card_image[0]}  className="image-building-image" alt={items.card_image_alt ?items.card_image_alt : items.card_image_title[1]} title={items.card_image_title[1]}/>
                                                </div>
                                            </Link>
                                        ):( '')
                                    ):('')}
                                </div>
                                <div className="plan-img-info">
                                    {items.promotion_info ? (
                                        items.promotion_info.promo_snipe  && items.promotion_info.promo_link ? (
                                            <a href={items.promotion_info.promo_link}><span className="card-snipe" style={{backgroundColor:items.promotion_info.promo_snipe_color}}
                                            tabindex="0"
                                            onKeyPress={(e)=>{
                                                if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                                            }}>{items.promotion_info.promo_snipe}</span></a>
                                        ):(
                                            items.promotion_info.promo_icon.url ? (
                                                <a href={items.promotion_info.promo_link}><span className="promo-icon" 
                                                tabindex="0"
                                                onKeyPress={(e)=>{
                                                    if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                                                }}><img src={items.promotion_info.promo_icon.url} alt={items.promotion_info.promo_icon.alt ? items.promotion_info.promo_icon.alt : items.promotion_info.promo_icon.title} title={items.promotion_info.promo_icon.title} /></span></a>
                                            ):('')
                                        )
                                    ):('')}
                                    <div className={"card-img-info " +(card_size)}>
                                        {items.post_status  ? (
                                            <span tabindex="0" className="card-status">{items.post_status}</span>
                                        ):('')}
                                        {items.post_title ? (
                                            <h3 tabindex="0" className="card-name">{items.post_title}</h3>
                                        ):('')}
                                        {
                                            items.city && items.state_abb && posttype ==="community"?(
                                                <p tabindex="0" className="card-price">{items.city},{" "}{items.state_abb}</p>
                                            ):('')  
                                        }
                                        {items.price_value && items.post_status!== "Sold Out" && posttype ==="community" ? (
                                            <p tabindex="0" className="card-price">{items.price_value}</p>
                                        ):('')}
                                        {items.price_value && items.post_
                                        !== "Sold" && posttype ==="qmi" ? (
                                         <p tabindex="0" className="card-price">{items.price_value}</p> 
                                        ):('')}
                                        
                                        {
                                            items.nbd_name && items.city && items .state && posttype ==="qmi"?(
                                                <p tabindex="0" className="card-price cardloc ">{items.nbd_name}  {" "} | {" "}  {items.city},{items.state}</p>
                                            ):('')  
                                        }
                                               
                                         {items.price_value && posttype ==="neighborhood" ? (
                                            <p tabindex="0" className="card-price">{items.price_value} </p>
                                        ):('')}
                                        {
                                            items.city && items.state_abb && posttype ==="neighborhood"?(
                                                <p tabindex="0" className="card-price">{items.city},{" "}{items.state_abb}</p>
                                            ):('')  
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="card-info">
                                <ul className="card-detail">
                                    {items.square_feet ? (
                                        <li>
                                            <span tabindex="0" className="info-value">{items.square_feet}</span>
                                            <span tabindex="0" className="info-name">Sq.{'\u00A0'}Footage</span>
                                        </li>
                                    ):('')}
                                    {items.bed ? (
                                        <li>
                                            <span tabindex="0" className="info-value">{items.bed}</span>
                                            <span tabindex="0" className="info-name">
                                                {items.bed === '1' ? ('Bedroom'):('Bedrooms')}
                                            </span>
                                        </li>
                                    ):('')}
                                    {items.bath ? (
                                        <li>
                                        <span tabindex="0" className="info-value">{items.bath}</span>
                                        <span tabindex="0" className="info-name">
                                            {items.bath === '1' ? ('Bathroom'):('Bathrooms')}
                                        </span>
                                    </li>
                                    ):('')}
                                    {items.garage ? (
                                        <li>
                                        <span tabindex="0" className="info-value">{items.garage}</span>
                                        <span tabindex="0" className="info-name">Bay Garage</span>
                                    </li>
                                    ):('')}
                                </ul>
                            </div>
                            </div>
                        ):('')))
                    ]:['']}
                    </div>
                    ) : (comm_card.length > 2 && window_width > 480 ? (
                        <div className="common-card promo">
                    <OwlCarousel {...card_options} className="owl-theme">
                    {comm_card.length > 0 ? [
                        comm_card.map(items => (
                            items.post_status!== "Archive" ? (
                        <div className="card-main-div col-lg-12 col-md-12 col-sm-12">
                            <div className="card-imgs">
                                <div className="plan-img">
                                    {items.card_image.length > 0 ? (
                                        items.card_image.length === 1 ? (
                                            <Link to={items.post_permalink} >
                                                <div className="overlay">
                                                    <img src={items.card_image[0]}  className="image-building-image" alt={items.card_image_alt ?items.card_image_alt : items.card_image_title[1]} title={items.card_image_title[1]}/>
                                                </div>
                                            </Link>
                                        ):( '')
                                    ):('')}
                                </div>
                                <div className="plan-img-info">
                                    {items.promotion_info ? (
                                        items.promotion_info.promo_snipe && items.promotion_info.promo_link ? (
                                            <a href={items.promotion_info.promo_link}><span className="card-snipe" style={{backgroundColor:items.promotion_info.promo_snipe_color}}
                                            tabindex="0"
                                            onKeyPress={(e)=>{
                                                if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                                            }}>{items.promotion_info.promo_snipe}</span></a>
                                        ):(
                                            items.promotion_info.promo_icon.url ? (
                                                <a href={items.promotion_info.promo_link}><span className="promo-icon" 
                                                tabindex="0"
                                                onKeyPress={(e)=>{
                                                    if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                                                }}><img src={items.promotion_info.promo_icon.url} alt={items.promotion_info.promo_icon.alt ? items.promotion_info.promo_icon.alt : items.promotion_info.promo_icon.title} title={items.promotion_info.promo_icon.title} /></span></a>
                                            ):('')
                                        )
                                    ):('')}
                                    <div className={"card-img-info " +(card_size)}>
                                        {items.post_status  ? (
                                            <span tabindex="0" className="card-status">{items.post_status}</span>
                                        ):('')}
                                        {items.post_title ? (
                                            <h3 tabindex="0" className="card-name">{items.post_title}</h3>
                                        ):('')}
                                        {
                                            items.city && items.state_abb && posttype ==="community"?(
                                                <p tabindex="0" className="card-price">{items.city},{" "}{items.state_abb}</p>
                                            ):('')  
                                        }
                                        {items.price_value && items.post_status!== "Sold Out" && posttype ==="community" ? (
                                            <p tabindex="0" className="card-price">{items.price_value}</p>
                                        ):('')}
                                        {items.price_value && items.post_
                                        !== "Sold" && posttype ==="qmi" ? (
                                         <p tabindex="0" className="card-price">{items.price_value}</p> 
                                        ):('')}
                                        
                                        {
                                            items.nbd_name && items.city && items .state && posttype ==="qmi"?(
                                                <p tabindex="0" className="card-price cardloc ">{items.nbd_name}  {" "} | {" "}  {items.city},{items.state}</p>
                                            ):('')  
                                        }
                                               
                                         {items.price_value && posttype ==="neighborhood" ? (
                                            <p tabindex="0" className="card-price">{items.price_value} </p>
                                        ):('')}
                                        {
                                            items.city && items.state_abb && posttype ==="neighborhood"?(
                                                <p tabindex="0" className="card-price">{items.city},{" "}{items.state_abb}</p>
                                            ):('')  
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="card-info">
                                <ul className="card-detail">
                                    {items.square_feet ? (
                                        <li>
                                            <span tabindex="0" className="info-value">{items.square_feet}</span>
                                            <span tabindex="0" className="info-name">Sq.{'\u00A0'}Footage</span>
                                        </li>
                                    ):('')}
                                    {items.bed ? (
                                        <li>
                                            <span tabindex="0" className="info-value">{items.bed}</span>
                                            <span tabindex="0" className="info-name">
                                                {items.bed === '1' ? ('Bedroom'):('Bedrooms')}
                                            </span>
                                        </li>
                                    ):('')}
                                    {items.bath ? (
                                        <li>
                                        <span tabindex="0" className="info-value">{items.bath}</span>
                                        <span tabindex="0" className="info-name">
                                            {items.bath === '1' ? ('Bathroom'):('Bathrooms')}
                                        </span>
                                    </li>
                                    ):('')}
                                    {items.garage ? (
                                        <li>
                                        <span tabindex="0" className="info-value">{items.garage}</span>
                                        <span tabindex="0" className="info-name">Bay Garage</span>
                                    </li>
                                    ):('')}
                                </ul>
                            </div>
                            </div>
                        ):('')))
                    ]:['']}
                    </OwlCarousel>
                            </div>
                        ) : (comm_card.length > 1 && window_width <= 480 ? (
                            <div className="common-card promo">
                    <OwlCarousel {...card_options} className="owl-theme">
                    {comm_card.length > 0 ? [
                        comm_card.map(items => (
                            items.post_status!== "Archive" ? (
                        <div className="card-main-div col-lg-12 col-md-12 col-sm-12">
                            <div className="card-imgs">
                                <div className="plan-img">
                                    {items.card_image.length > 0 ? (
                                        items.card_image.length === 1 ? (
                                            <Link to={items.post_permalink} >
                                                <div className="overlay">
                                                    <img src={items.card_image[0]}  className="image-building-image" alt={items.card_image_alt ?items.card_image_alt : items.card_image_title[1]} title={items.card_image_title[1]}/>
                                                </div>
                                            </Link>
                                        ):( '')
                                    ):('')}
                                </div>
                                <div className="plan-img-info">
                                    {items.promotion_info ? (
                                        items.promotion_info.promo_snipe && items.promotion_info.promo_link ? (
                                            <a href={items.promotion_info.promo_link}><span className="card-snipe" style={{backgroundColor:items.promotion_info.promo_snipe_color}}
                                            tabindex="0"
                                            onKeyPress={(e)=>{
                                                if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                                            }}>{items.promotion_info.promo_snipe}</span></a>
                                        ):(
                                            items.promotion_info.promo_icon.url ? (
                                                <a href={items.promotion_info.promo_link}><span className="promo-icon" 
                                                tabindex="0"
                                                onKeyPress={(e)=>{
                                                    if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                                                }}><img src={items.promotion_info.promo_icon.url} alt={items.promotion_info.promo_icon.alt ? items.promotion_info.promo_icon.alt : items.promotion_info.promo_icon.title} title={items.promotion_info.promo_icon.title} /></span></a>
                                            ):('')
                                        )
                                    ):('')}
                                    <div className={"card-img-info " +(card_size)}>
                                        {items.post_status  ? (
                                            <span tabindex="0" className="card-status">{items.post_status}</span>
                                        ):('')}
                                        {items.post_title ? (
                                            <h3 tabindex="0" className="card-name">{items.post_title}</h3>
                                        ):('')}
                                        {
                                            items.city && items.state_abb && posttype ==="community"?(
                                                <p tabindex="0" className="card-price">{items.city},{" "}{items.state_abb}</p>
                                            ):('')  
                                        }
                                        {items.price_value && items.post_status!== "Sold Out" && posttype ==="community" ? (
                                            <p tabindex="0" className="card-price">{items.price_value}</p>
                                        ):('')}
                                        {items.price_value && items.post_
                                        !== "Sold" && posttype ==="qmi" ? (
                                         <p tabindex="0" className="card-price">{items.price_value}</p> 
                                        ):('')}
                                        
                                        {
                                            items.nbd_name && items.city && items .state && posttype ==="qmi"?(
                                                <p tabindex="0" className="card-price cardloc ">{items.nbd_name}  {" "} | {" "}  {items.city},{items.state}</p>
                                            ):('')  
                                        }
                                               
                                         {items.price_value && posttype ==="neighborhood" ? (
                                            <p tabindex="0" className="card-price">{items.price_value} </p>
                                        ):('')}
                                        {
                                            items.city && items.state_abb && posttype ==="neighborhood"?(
                                                <p tabindex="0" className="card-price">{items.city},{" "}{items.state_abb}</p>
                                            ):('')  
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="card-info">
                                <ul className="card-detail">
                                    {items.square_feet ? (
                                        <li>
                                            <span tabindex="0" className="info-value">{items.square_feet}</span>
                                            <span tabindex="0" className="info-name">Sq.{'\u00A0'}Footage</span>
                                        </li>
                                    ):('')}
                                    {items.bed ? (
                                        <li>
                                            <span tabindex="0" className="info-value">{items.bed}</span>
                                            <span tabindex="0" className="info-name">
                                                {items.bed === '1' ? ('Bedroom'):('Bedrooms')}
                                            </span>
                                        </li>
                                    ):('')}
                                    {items.bath ? (
                                        <li>
                                        <span tabindex="0" className="info-value">{items.bath}</span>
                                        <span tabindex="0" className="info-name">
                                            {items.bath === '1' ? ('Bathroom'):('Bathrooms')}
                                        </span>
                                    </li>
                                    ):('')}
                                    {items.garage ? (
                                        <li>
                                        <span tabindex="0" className="info-value">{items.garage}</span>
                                        <span tabindex="0" className="info-name">Bay Garage</span>
                                    </li>
                                    ):('')}
                                </ul>
                            </div>
                            </div>
                        ):('')))
                    ]:['']}
                    </OwlCarousel>
                            </div>
                        ) : (<div className="common-card promo">
                        {comm_card.length > 0 ? [
                            comm_card.map(items => (
                                items.post_status!== "Archive" ? (
                            <div className="card-main-div col-lg-6 col-sm-6">
                                <div className="card-imgs">
                                    <div className="plan-img">
                                        {items.card_image.length > 0 ? (
                                            items.card_image.length === 1 ? (
                                                <Link to={items.post_permalink} >
                                                    <div className="overlay">
                                                        <img src={items.card_image[0]}  className="image-building-image" alt={items.card_image_alt ?items.card_image_alt : items.card_image_title[1]} title={items.card_image_title[1]}/>
                                                    </div>
                                                </Link>
                                            ):( '')
                                        ):('')}
                                    </div>
                                    <div className="plan-img-info">
                                        {items.promotion_info ? (
                                            items.promotion_info.promo_snipe && items.promotion_info.promo_link ? (
                                                <a href={items.promotion_info.promo_link}><span className="card-snipe" style={{backgroundColor:items.promotion_info.promo_snipe_color}}
                                                tabindex="0"
                                                onKeyPress={(e)=>{
                                                    if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                                                }}>{items.promotion_info.promo_snipe}</span></a>
                                            ):(
                                                items.promotion_info.promo_icon.url ? (
                                                    <a href={items.promotion_info.promo_link}><span className="promo-icon" 
                                                    tabindex="0"
                                                    onKeyPress={(e)=>{
                                                        if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                                                    }}><img src={items.promotion_info.promo_icon.url} alt={items.promotion_info.promo_icon.alt ? items.promotion_info.promo_icon.alt : items.promotion_info.promo_icon.title} title={items.promotion_info.promo_icon.title} /></span></a>
                                                ):('')
                                            )
                                        ):('')}
                                        <div className={"card-img-info " +(card_size)}>
                                            {items.post_status  ? (
                                                <span tabindex="0" className="card-status">{items.post_status}</span>
                                            ):('')}
                                            {items.post_title ? (
                                                <h3 tabindex="0" className="card-name">{items.post_title}</h3>
                                            ):('')}
                                            {
                                            items.city && items.state_abb && posttype ==="community"?(
                                                <p tabindex="0" className="card-price ">{items.city},{" "}{items.state_abb}</p>
                                            ):('')  
                                            }
                                            {items.price_value && items.post_status!== "Sold Out" && posttype ==="community" ? (
                                                <p tabindex="0" className="card-price">{items.price_value}</p>
                                            ):('')}
                                            {items.price_value && items.post_
                                            !== "Sold" && posttype ==="qmi" ? (
                                             <p tabindex="0" className="card-price">{items.price_value}</p>   
                                            ):('')}
                                            
                                            {
                                                items.nbd_name && items.city && items .state && posttype ==="qmi"?(
                                                    <p tabindex="0" className="card-price cardloc ">{items.nbd_name}  {" "} | {" "}  {items.city},{items.state}</p>
                                                ):('')  
                                            }
                                                   
                                             {items.price_value && posttype ==="neighborhood" ? (
                                                <p tabindex="0" className="card-price">{items.price_value} </p>
                                            ):('')}
                                            {
                                            items.city && items.state_abb && posttype ==="neighborhood"?(
                                                <p tabindex="0" className="card-price">{items.city},{" "}{items.state_abb}</p>
                                            ):('')  
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="card-info">
                                    <ul className="card-detail">
                                        {items.square_feet ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.square_feet}</span>
                                                <span tabindex="0" className="info-name">Sq.{'\u00A0'}Footage</span>
                                            </li>
                                        ):('')}
                                        {items.bed ? (
                                            <li>
                                                <span tabindex="0" className="info-value">{items.bed}</span>
                                                <span tabindex="0" className="info-name">
                                                    {items.bed === '1' ? ('Bedroom'):('Bedrooms')}
                                                </span>
                                            </li>
                                        ):('')}
                                        {items.bath ? (
                                            <li>
                                            <span tabindex="0" className="info-value">{items.bath}</span>
                                            <span tabindex="0" className="info-name">
                                                {items.bath === '1' ? ('Bathroom'):('Bathrooms')}
                                            </span>
                                        </li>
                                        ):('')}
                                        {items.garage ? (
                                            <li>
                                            <span tabindex="0" className="info-value">{items.garage}</span>
                                            <span tabindex="0" className="info-name">Bay Garage</span>
                                        </li>
                                        ):('')}
                                    </ul>
                                </div>
                                </div>
                            ):('')))
                        ]:['']}
                        </div>))
                    )
                
                ://for state,submarket and city
                comm_card.length > 1 && window_width <= 480 ? 
                <div className="common-card promo">
                <OwlCarousel {...card_options} className="owl-theme">
                {comm_card.length > 0 ? [
                    comm_card.map(items => (
                        items.post_status!== "Archive" ? (
                    <div className="card-main-div col-lg-12 col-md-12 col-sm-12">
                        <div className="card-imgs">
                            <div className="plan-img">
                                {items.card_image.length > 0 ? (
                                    items.card_image.length === 1 ? (
                                        <Link to={items.post_permalink} >
                                            <div className="overlay">
                                                <img src={items.card_image[0]}  className="image-building-image" alt={items.card_image_alt ?items.card_image_alt : items.card_image_title[1]} title={items.card_image_title[1]}/>
                                            </div>
                                        </Link>
                                    ):( '')
                                ):('')}
                            </div>
                            <div className="plan-img-info">
                                {items.promotion_info ? (
                                    items.promotion_info.promo_snipe && items.promotion_info.promo_link? (
                                        <a href={items.promotion_info.promo_link}><span className="card-snipe" style={{backgroundColor:items.promotion_info.promo_snipe_color}}
                                        tabindex="0"
                                        onKeyPress={(e)=>{
                                            if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                                        }}>{items.promotion_info.promo_snipe}</span></a>
                                    ):(
                                        items.promotion_info.promo_icon.url ? (
                                            <a href={items.promotion_info.promo_link}><span className="promo-icon" 
                                            tabindex="0"
                                            onKeyPress={(e)=>{
                                                if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                                            }}><img src={items.promotion_info.promo_icon.url} alt={items.promotion_info.promo_icon.alt ? items.promotion_info.promo_icon.alt : items.promotion_info.promo_icon.title} title={items.promotion_info.promo_icon.title} /></span></a>
                                        ):('')
                                    )
                                ):('')}
                                <div className={"card-img-info " +(card_size)}>
                                    {items.post_status  ? (
                                        <span tabindex="0" className="card-status">{items.post_status}</span>
                                    ):('')}
                                    {items.post_title ? (
                                        <h3 tabindex="0" className="card-name">{items.post_title}</h3>
                                    ):('')}
                                    
                                    {items.price_value && items.post_status!== "Sold Out" && posttype ==="community" ? (
                                        <p tabindex="0" className="card-price">{items.price_value}</p>
                                    ):('')}
                                    {items.price_value && items.post_
                                    !== "Sold" && posttype ==="qmi" ? (
                                     <p tabindex="0" className="card-price">{items.price_value}</p> 
                                        
                                        
                                    ):('')}
                                    {
                                        items.nbd_name && items.city && items .state && posttype ==="qmi"?(
                                            <p tabindex="0" className="card-price cardloc ">{items.nbd_name}  {" "} | {" "}  {items.city},{items.state}</p>
                                        ):('')  
                                    }
                                           
                                     {items.price_value && posttype ==="neighborhood" ? (
                                        <p tabindex="0" className="card-price">{items.price_value} </p>
                                    ):('')}
                                    
                                </div>
                            </div>
                        </div>
                        <div className="card-info">
                            <ul className="card-detail">
                                {items.square_feet ? (
                                    <li>
                                        <span tabindex="0" className="info-value">{items.square_feet}</span>
                                        <span tabindex="0" className="info-name">Sq.{'\u00A0'}Footage</span>
                                    </li>
                                ):('')}
                                {items.bed ? (
                                    <li>
                                        <span tabindex="0" className="info-value">{items.bed}</span>
                                        <span tabindex="0" className="info-name">
                                            {items.bed === '1' ? ('Bedroom'):('Bedrooms')}
                                        </span>
                                    </li>
                                ):('')}
                                {items.bath ? (
                                    <li>
                                    <span tabindex="0" className="info-value">{items.bath}</span>
                                    <span tabindex="0" className="info-name">
                                        {items.bath === '1' ? ('Bathroom'):('Bathrooms')}
                                    </span>
                                </li>
                                ):('')}
                                {items.garage ? (
                                    <li>
                                    <span tabindex="0" className="info-value">{items.garage}</span>
                                    <span tabindex="0" className="info-name">Bay Garage</span>
                                </li>
                                ):('')}
                            </ul>
                        </div>
                        </div>
                    ):('')))
                ]:['']}
                </OwlCarousel>
                        </div>
                :
                <div className="common-card non-promo">
                    {comm_card.length > 0 ? [
                        comm_card.map(items => (
                            items.post_status!== "Archive" ? (
                        <div className="card-main-div col-lg-6 col-sm-6">
                            <div className="card-imgs">
                                <div className="plan-img">
                                    {items.card_image.length > 0 ? (
                                        items.card_image.length === 1 ? (
                                            <Link to={items.post_permalink} >
                                                <div className="overlay">
                                                    <img src={items.card_image[0]}  className="image-building-image" alt={items.card_image_alt ?items.card_image_alt : items.card_image_title[1]} title={items.card_image_title[1]}/>
                                                </div>
                                            </Link>
                                        ):( '')
                                    ):('')}
                                </div>
                                <div className="plan-img-info">
                                    {items.promotion_info ? (
                                        items.promotion_info.promo_snipe && items.promotion_info.promo_link? (
                                            <a href={items.promotion_info.promo_link}><span className="card-snipe" style={{backgroundColor:items.promotion_info.promo_snipe_color}}
                                            tabindex="0"
                                            onKeyPress={(e)=>{
                                                if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                                            }}>{items.promotion_info.promo_snipe}</span></a>
                                        ):(
                                            items.promotion_info.promo_icon.url ? (
                                                <a href={items.promotion_info.promo_link}><span className="promo-icon" 
                                                tabindex="0"
                                                onKeyPress={(e)=>{
                                                    if(e.keyCode == 13 || e.which == 13) e.target.click(); 
                                                }}><img src={items.promotion_info.promo_icon.url} alt={items.promotion_info.promo_icon.alt ? items.promotion_info.promo_icon.alt : items.promotion_info.promo_icon.title} title={items.promotion_info.promo_icon.title} /></span></a>
                                            ):('')
                                        )
                                    ):('')}
                                    <div className={"card-img-info " +(card_size)}>
                                        {items.post_status  ? (
                                            <span tabindex="0" className="card-status">{items.post_status}</span>
                                        ):('')}
                                        {items.post_title ? (
                                            <h3 tabindex="0" className="card-name">{items.post_title}</h3>
                                        ):('')}
                                        
                                        {items.price_value && items.post_status!== "Sold Out" && posttype ==="community" ? (
                                            <p tabindex="0" className="card-price">{items.price_value}</p>
                                        ):('')}
                                        {items.price_value && items.post_
                                        !== "Sold" && posttype ==="qmi" ? (
                                         <p tabindex="0" className="card-price">{items.price_value}</p> 
                                            
                                            
                                        ):('')}
                                        {
                                            items.nbd_name && items.city && items .state && posttype ==="qmi"?(
                                                <p tabindex="0" className="card-price cardloc ">{items.nbd_name}  {" "} | {" "}  {items.city},{items.state}</p>
                                            ):('')  
                                        }
                                               
                                         {items.price_value && posttype ==="neighborhood" ? (
                                            <p tabindex="0" className="card-price">{items.price_value} </p>
                                        ):('')}
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="card-info">
                                <ul className="card-detail">
                                    {items.square_feet ? (
                                        <li>
                                            <span tabindex="0" className="info-value">{items.square_feet}</span>
                                            <span tabindex="0" className="info-name">Sq.{'\u00A0'}Footage</span>
                                        </li>
                                    ):('')}
                                    {items.bed ? (
                                        <li>
                                            <span tabindex="0" className="info-value">{items.bed}</span>
                                            <span tabindex="0" className="info-name">
                                                {items.bed === '1' ? ('Bedroom'):('Bedrooms')}
                                            </span>
                                        </li>
                                    ):('')}
                                    {items.bath ? (
                                        <li>
                                        <span tabindex="0" className="info-value">{items.bath}</span>
                                        <span tabindex="0" className="info-name">
                                            {items.bath === '1' ? ('Bathroom'):('Bathrooms')}
                                        </span>
                                    </li>
                                    ):('')}
                                    {items.garage ? (
                                        <li>
                                        <span tabindex="0" className="info-value">{items.garage}</span>
                                        <span tabindex="0" className="info-name">Bay Garage</span>
                                    </li>
                                    ):('')}
                                </ul>
                            </div>
                            </div>
                        ):('')))
                    ]:['']}
                </div>
            ]:['']
        ); 
    }  
}  

export default CommonNeighCard;
