import React from 'react'
import {Form} from 'react-bootstrap'
import SectionHeader from './SectionHeader'
import $ from "jquery"
import { srcset } from './Helpers'
import './MortgageSection.scss'
import cookie from 'react-cookies';
import axios from 'axios';
import { apiConfig } from './Helper.jsx';

class MortgageSection extends React.Component {
  mortgage_calculation(){
    $('.mortgage_field').on('keyup change', function() {
      const mor_amount = $('#mortgage_amount').val()
      const mor_interest = $('#interest').val()
      const mor_period = $('#period').val()
      const mor_frequency = $('#frequency').val()
      const frequncy_value = $('#frequency option:selected').text()

      if (mor_frequency !== '') {
        $('.frequency_data').text(frequncy_value)
      }

      if (mor_amount !== '' && mor_interest !== '') {
        const interest_value = mor_interest / mor_frequency / 100
        const term_value = mor_frequency * mor_period
        const monthly_value = mor_amount * interest_value / (1 - Math.pow(1 + interest_value, -term_value))
        const monthly_amount = isNaN(Math.round(monthly_value)) ? 0 : Math.round(monthly_value)

        $('[data-monthly-amount]').text(monthly_amount);
        if (monthly_amount > 0 ) {
          window.dataLayer.push({'event':'mortgageCalculator'});
          function fetchData() {
            return new Promise((resolve, reject) => {
                axios.post(apiConfig().API_URL + '/get_marketo_lead_data/',{
                    'get_type': "Activity",
                    'activity_type' : "MortgageCalculator",
                    'cookie' : cookie.load('_mkto_trk'),
                    'URL' : window.location.href,
                    'value' : monthly_amount
                }).then(response => response)
                .then(data => resolve(data))
                .catch(error => reject(error));
            });
          }
          var mkto_confirm = cookie.load('_mkto_trk');
          if (mkto_confirm) {
              fetchData()
                .then(data => {
                  console.log(data);
                })
                .catch(error => console.error(error));
          }
        }
      }
    })
  }

  componentDidMount() {
    this.mortgage_calculation()

    const data = this.props.mortgage_data
    const section_id = data.section_id ? data.section_id.trim() : 'payment-calculator'

    if (section_id) {
      setTimeout(function() {
        if (window.location.href.indexOf("#" + section_id) > -1) {
          document.querySelector("#" + section_id).scrollIntoView({behavior:'smooth', block:'start'})
        }
      }, 2000)
    }

    document.querySelectorAll('.global-components > *:last-child').forEach(el => {
      if (!el.classList.contains('section-margin')) el.closest('.global-components').classList.add('section-margin-bottom')
    })

    window.lazy.update()
  }
  mortgageCalculatorClick_data_layer_push(param) {
    this.setState({ value:param })
    const morCl_amount = $('#mortgage_amount').val()
      const morCl_interest = $('#interest').val()
      if (morCl_amount && morCl_interest) {
        if (param && param == 'mortgageCalculator') {
          window.dataLayer.push({'event':'mortgageCalculator'})
        }
      }
  }
  render() {
    const data = this.props.mortgage_data
    const promo_data = this.props.promo_data
    const description = data.description && data.description !== '' ? data.description : data.default_description ? data.default_description : null
    const disclaimer = data.disclaimer && data.disclaimer !== '' ? data.disclaimer : data.default_disclaimer ? data.default_disclaimer : null
    const eyebrow = data.sub_text && data.sub_text !== '' ? data.sub_text : data.default_sub_text ? data.default_sub_text : 'Payment Calculator'
    const headline_part_1 = data.headline && data.headline !== '' ? data.headline : data.default_headline ? data.default_headline : 'Estimate your'
    const headline_part_2 = data.headline_part_2 && data.headline_part_2 !== '' ? data.headline_part_2 : data.default_headline_part_2 ? data.default_headline_part_2 : 'Payment'
    const mortgage_option_text = data.option_text && data.option_text !== '' ? data.option_text : 'Enter your mortgage amount'
    const section_id = data.section_id ? data.section_id.trim() : 'payment-calculator'

    return (
      <section className="relative form-grid background-fill section-padding wrap"
        data-sticky-subnav-section="payment-calculator"
        style={{
          "--background-color": "#FAFAFA",
        }}>
        <div class="section-padding" id={section_id}>
          <SectionHeader
            eyebrow={eyebrow}
            headline_part_1={headline_part_1}
            headline_part_2={headline_part_2}
            headline_part_1_color={data.headline_part_1_color}
            headline_part_2_color={data.headline_part_2_color}
            headline_part_2_class="title__highlight block"
            headline_size={data.hasOwnProperty('headline_size') ? data.headline_size : 'large'}
          />

          <div className="relative flex form-grid__grid"
            style={{
              "--flex-display-mobile": "block",
              "--flex-justify-content": "space-between"
            }}>
            <div className="relative form-grid__lockup padding-horizontal width"
              style={{
                "--padding-horizontal-right-desktop": "140px",
                "--width-desktop": "calc(50% - 25px)",
                "--width-mobile": "100%"
              }}>
              <h3 className="media-lockup__subtitle font--medium font-size margin-vertical hide-mobile"
                style={{
                  "--font-size-desktop": "24px",
                  "--font-size-mobile": "18px",
                  "--margin-vertical-bottom-desktop": "25px",
                  "--margin-vertical-bottom-mobile": "25px"
                }}>
                {data.monthly_payment_text ? data.monthly_payment_text : 'Your monthly payment would be**' } $<span data-monthly-amount>0</span>
              </h3>

              {description &&
                <div className="form-grid__lockup-copy-- font-size-- description margin-vertical rte"
                  style={{
                    "--margin-vertical-top-mobile": "40px",
                    "max-width": "580px",
                  }}
                  dangerouslySetInnerHTML={{__html:description.replace(/(?:\r\n|\r|\n)/g, '')}}>
                </div>
              }

              {data.cta_label && data.cta_link &&
                <a class="button margin-vertical"
                  style={{
                    '--margin-vertical-bottom-desktop': '0',
                    '--margin-vertical-bottom-mobile': '0',
                    '--margin-vertical-top-desktop': '65px',
                    '--margin-vertical-top-mobile': '40px',
                    '--btn-background-color': promo_data ? promo_data.promotion_accent_color : '',
                    '--btn-background-color-hover': promo_data ? promo_data.promotion_accent_color : ''
                  }}
                  href={data.cta_link} onClick={() => {this.mortgageCalculatorClick_data_layer_push('mortgageCalculator')}}>
                  <span>{data.cta_label}</span>
                </a>
              }

              <div className="absolute form-grid__form font-size margin-vertical padding-horizontal padding-vertical width"
                style={{
                  "--position-desktop": "absolute",
                  "--position-mobile": "static",
                  "--font-size-desktop": "24px",
                  "--font-size-mobile": "18px",
                  "--font-size-line-height-desktop": "1.333",
                  "--font-size-line-height-mobile": "1.375",
                  "--left": "calc(100% - 126px)",
                  "--margin-vertical-top-mobile": "10px",
                  "--padding-vertical": "88px 57px 111px",
                  "--padding-horizontal-left-desktop": "30px",
                  "--padding-horizontal-left-desktop": "clamp(30px, 2.96875vw, 57px)",
                  "--padding-horizontal-left-mobile": "30px",
                  "--padding-horizontal-right-desktop": "30px",
                  "--padding-horizontal-right-desktop": "clamp(30px, 2.96875vw, 57px)",
                  "--padding-horizontal-right-mobile": "30px",
                  "--padding-vertical-bottom-desktop": "40px",
                  "--padding-vertical-bottom-desktop": "clamp(40px, 5.78125vw, 111px)",
                  "--padding-vertical-bottom-mobile": "80px",
                  "--padding-vertical-top-desktop": "40px",
                  "--padding-vertical-top-desktop": "clamp(40px, 4.58333vw, 88px",
                  "--padding-vertical-top-mobile": "60px",
                  "--top": "-42px",
                  "--width-desktop": "clamp(380px, 30vw, 583px)",
                  "--width-mobile": "100%",
                  "background-color": "#fff",
                  "z-index": "1"
                }}>
                <Form aria-label="calculator" className="mortgage-calculator">
                  <Form.Group controlId="mortgage_amount">
                    <Form.Label>{mortgage_option_text}</Form.Label>
                    <Form.Control className="mortgage_field" type="Number"/>
                  </Form.Group>

                  <Form.Group controlId="interest">
                    <Form.Label>Interest rate</Form.Label>
                    <Form.Control className="mortgage_field" type="Number"/>
                  </Form.Group>

                  <Form.Group controlId="period">
                    <Form.Label>Amortization Period</Form.Label>
                    <Form.Control className="mortgage_field" as="select">
                        <option value="1">1 Year</option>
                        <option value="3">3 Years</option>
                        <option value="5">5 Years</option>
                        <option value="10">10 Years</option>
                        <option value="15">15 Years</option>
                        <option value="25">25 Years</option>
                        <option value="30" selected="selected">30 Years</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="frequency">
                    <Form.Label>Payment Frequency</Form.Label>
                    <Form.Control  className="mortgage_field" as="select">
                      <option value="12">Monthly</option>
                      <option value="24">Semi-monthly</option>
                      <option value="52">Weekly</option>
                      <option value="26">Bi-weekly</option>
                    </Form.Control>
                  </Form.Group>
                </Form>

                <div className="media-lockup__subtitle font--medium font-size margin-vertical"
                  style={{
                    "--font-size-desktop": "20px",
                    "--font-size-mobile": "16px",
                    "--margin-vertical-bottom-desktop": "0",
                    "--margin-vertical-bottom-mobile": "0",
                    "--margin-vertical-top-desktop": "20px",
                    "--margin-vertical-top-mobile": "15px",
                  }}>
                  <div>{data.calculator_payment_text ? data.calculator_payment_text : 'Your monthly payment would be**'}</div>
                  <div>$<span data-monthly-amount>0</span></div>
                </div>
              </div>

              {disclaimer &&
                <div className="media-card__copy font-size margin-vertical rte"
                  style={{
                    "--font-size-desktop": "12px",
                    "--font-size-mobile": "10px",
                    "--font-size-line-height-desktop": "1.333",
                    "--font-size-line-height-mobile": "1.375",
                    // "--margin-vertical-bottom-desktop": "140px",
                    "--margin-vertical-bottom-desktop": "0",
                    "--margin-vertical-top-desktop": "80px",
                    "--margin-vertical-top-mobile": "40px",
                    "color": "#969696",
                    "max-width": "var(--media-card-copy-max-width, 70ch)"
                  }}>
                  <p>{disclaimer}</p>
                </div>
              }
            </div>

            <figure className="form-grid__medium aspect-ratio margin-vertical width"
              style={{
                "--aspect-ratio-height-desktop": "1085",
                "--aspect-ratio-height-mobile": "1085",
                "--aspect-ratio-width-desktop": "924",
                "--aspect-ratio-width-mobile": "924",
                "--margin-vertical-top-mobile": "70px",
                "--margin-vertical-top-desktop": "-167px",
                "--width-desktop": "calc(50% - 25px)",
                "--width-mobile": "100%",
                "background-color": "#969696"
              }}>
              {data.form_image &&
                <img className="media-staggered__media-item lazy object-fit"
                  alt={data.form_image.alt ? data.form_image.alt : data.form_image.title}
                  data-srcset={srcset(data.form_image)}
                  sizes="auto"
                />
              }
            </figure>
          </div>
        </div>
      </section>
    )
  }
}

export default MortgageSection