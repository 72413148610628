import React from 'react';
import './PromoQmiList.scss';
import PromoQMICard from './PromoQMICard';

const PER_PAGE = 24;

function sortByNeighborhoodPrice(qmis, dir = 'ASC') {
    const sorted = qmis
        .sort((a, b) =>
            dir == 'ASC'
                ? parseInt(a.price) - parseInt(b.price) || a.post_title.localeCompare(b.post_title)
                : parseInt(b.price) - parseInt(a.price) || a.post_title.localeCompare(b.post_title)
        )
        .reduce((groups, qmi) => {
            if (!groups[qmi.nbd_name]) {
                groups[qmi.nbd_name] = [];
            }
            groups[qmi.nbd_name].push(qmi);
            return groups;
        }, []);
    return Object.values(sorted).flat();
}

function sortByNeighborhoodName(qmis, dir = 'ASC') {
    const priceSorted = qmis.sort(function (a, b) {
        return parseInt(a.price) - parseInt(b.price) || a.post_title.localeCompare(b.post_title);
    });

    return priceSorted.sort(function (a, b) {
        return dir == 'ASC' ? a.nbd_name.localeCompare(b.nbd_name) : b.nbd_name.localeCompare(a.nbd_name);
    });
}

function sortByPrice(qmis, dir = 'ASC') {
    return qmis.sort((a, b) =>
        dir == 'ASC'
            ? parseInt(a.price) - parseInt(b.price) || a.post_title.localeCompare(b.post_title)
            : parseInt(b.price) - parseInt(a.price) || a.post_title.localeCompare(b.post_title)
    );
}

export default function PromoQMIList({ qmis, listConfig, promoSnipe, promoSnipeColor, promoAccentColor }) {
    const [currPage, setCurrPage] = React.useState(1);
    const [sortedQMIs, setSortedQMIs] = React.useState([]);

    React.useEffect(() => {
        switch (listConfig.qmi_list_sort) {
            case 'neighborhood_price_asc':
                setSortedQMIs(sortByNeighborhoodPrice(qmis, 'ASC'));
                break;
            case 'neighborhood_price_desc':
                setSortedQMIs(sortByNeighborhoodPrice(qmis, 'DESC'));
                break;
            case 'neighborhood_alpha_asc':
                setSortedQMIs(sortByNeighborhoodName(qmis, 'ASC'));
                break;
            case 'neighborhood_alpha_desc':
                setSortedQMIs(sortByNeighborhoodName(qmis, 'DESC'));
                break;
            case 'price_asc':
                setSortedQMIs(sortByPrice(qmis, 'ASC'));
                break;
            case 'price_desc':
                setSortedQMIs(sortByPrice(qmis, 'DESC'));
                break;
        }
    }, [qmis]);

    const onLoadMore = React.useCallback(() => {
        setCurrPage((prev) => prev + 1);
    }, []);

    const showMore = currPage * PER_PAGE < qmis.length;

    const { headline_part_1, headline_part_2 } = listConfig;

    return (
        <div style={{ marginBottom: '6rem' }}>
            <div className="sectiontitle-main-div" id="qmi-list">
                <h2 className={`sectiontitle-headline sectiontitle-headline--large gray-color`}>
                    <span
                        style={{ color: promoAccentColor }}
                        dangerouslySetInnerHTML={{
                            __html: headline_part_1.replace(/(?:\r\n|\r|\n)/g, ''),
                        }}
                    ></span>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: headline_part_2.replace(/(?:\r\n|\r|\n)/g, ''),
                        }}
                    ></span>
                </h2>
                <div className="eyebrow-text-div">
                    <div className="eyebrowtext-border"></div>
                </div>
            </div>
            <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                <div className="promo-qmi-list">
                    {sortedQMIs.slice(0, currPage * PER_PAGE).map((qmi) => (
                        <PromoQMICard
                            promoSnipe={promoSnipe}
                            promoSnipeColor={promoSnipeColor}
                            promoAccentColor={promoAccentColor}
                            key={qmi.post_id}
                            qmi={qmi}
                        />
                    ))}
                </div>
                {showMore && (
                    <button
                        style={{ '--accent-color': promoAccentColor }}
                        onClick={onLoadMore}
                        role="button"
                        className="promo-qmi-list__load-more"
                    >
                        Load More
                    </button>
                )}
            </div>
        </div>
    );
}
